import React from "react";
import cx from "classnames";
import Img from "gatsby-image";
import {
  list,
  listItem,
  flipped,
  title,
  links,
  link,
  image,
  article
} from "./List.module.css";

const List = props => <ul className={list}>{props.children}</ul>;

export const ListItem = props => (
  <li
    className={cx(listItem, {
      [flipped]: props.flipped
    })}
  >
    <a style={{ display: "block", width: "100%" }} href={props.href}>
      <Img
        href={props.href}
        className={image}
        alt={`view ${props.title} slides`}
        fluid={props.imageSrc}
      />
    </a>
    <article className={article}>
      <h2 className={title}>{props.title}</h2>
      {props.children}
      <div className={links}>
        <a className={link} href={props.href}>
          {props.talk ? "slides" : "demo"}
        </a>
        {props.recording && (
          <a className={link} href={props.recording}>
            recording
          </a>
        )}
      </div>
    </article>
  </li>
);

export default List;
