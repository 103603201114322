import React from "react";
import List, { ListItem } from "../components/List.js";
import Header from "../components/Header.js";
import Page from "../components/Page.js";

export default ({ data }) => (
  <Page>
    <Header>Talks</Header>
    <List>
      <ListItem
        talk
        imageSrc={data.vfonts.childImageSharp.fluid}
        title="Variable Fonts Primer"
        recording="https://ec.yourlearning.ibm.com/w3/playback/10021607"
        href="https://fed-vf.now.sh"
      >
        <p>
          This presentation covers what variable fonts are, what they can do,
          and why you would use them. In this talk, I also announce the release
          of IBM Plex Sans Variable, the variable member of the Plex typeface
          developed by IBM.
        </p>
      </ListItem>
      <ListItem
        flipped
        talk
        imageSrc={data.tford.childImageSharp.fluid}
        title="Typography For Developers"
        href="https://typography-for-developers.now.sh"
      >
        <p>
          This talk presents typographic essentials to web developers and
          designers looking to bring beautiful type to the web. Topics include:
          selecting a typeface, font loading best-practices, and the future of
          web fonts.
        </p>
      </ListItem>
      <ListItem
        talk
        recording="https://www.gatsbyjs.com/ibm-themes-webinar"
        imageSrc={data.themes.childImageSharp.fluid}
        title="Gatsby Themes at IBM Design"
        href="https://gatsby-theme-carbon-webinar.now.sh/"
      >
        <p>
          In this live webinar to the Gatsby community, we cover the history of
          Carbon's <a href="https://www.carbondesignsystem.com">website</a> and
          how we're using Gatsby (a React framework) to create fast, accessible
          sites at IBM Design that adhere to the IBM Design language.
        </p>
      </ListItem>
    </List>
  </Page>
);

export const query = graphql`
  query {
    themes: file(name: { eq: "gatsby-themes" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tford: file(name: { eq: "tford" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vfonts: file(name: { eq: "vfonts" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
