import React from "react";
import { Link } from "gatsby";
import { container, headerText } from "./Header.module.css";

const Header = ({ children }) => {
  return (
    <div className={container}>
      <Link
        aria-label="return to home page"
        style={{ position: "absolute" }}
        className={headerText}
        to="/"
      >
        ←
      </Link>
      <h1 style={{ textAlign: "center", width: "100%" }} className={headerText}>
        {children}
      </h1>
    </div>
  );
};

export default Header;
